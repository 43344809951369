<template>
  <div class="view-offer-line" @click="toggleDisplay">
    <div class="row align-items-center pt-1 pb-1">
      <div class="col-4 pt-3 pb-3 supplier-name">
        <span class="fw-bold mb-0 ps-3">{{ line.headline }}</span>
      </div>
      <div class="col-3" v-if="line.levkod">
        {{ line.levkod.leverantor }}
      </div>
      <div class="col-3 text-muted" v-if="!line.levkod">
        {{ $t("supplierIsMissing") }}
      </div>
      <div class="col-1 text-end">
        <span class="mb-0 text-muted ps-3">{{ line.quantity }} st</span>
      </div>
      <div class="col-2 text-end">
        <div class="pe-4 d-block">
          {{
            parseFloat(line.final_price).toLocaleString("sv-SE", {
              style: "currency",
              currency: currency,
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            })
          }}
          / st
        </div>
      </div>
      <div class="col-2 text-end">
        <div class="pe-4 d-block fw-bold">
          {{
            (line.quantity * line.final_price).toLocaleString("sv-SE", {
              style: "currency",
              currency: currency,
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            })
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ViewLine",
  props: {
    line: Object,
    currency: { type: String, default: "SEK" },
  },
  methods: {
    toggleDisplay() {
      this.line.readonly = false;
      this.$emit("toggle-display", this.line.line_number, this.line);
    },
  },
};
</script>
