<template>
  <main-layout>
    <div class="page-header" v-if="isLoading">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-6">
            <div class="pt-3 pb-3">
              <h5 class="mb-0">
                {{ $t("getOffer") }}
                <i class="ms-4 fas fa-circle-notch fa-spin"></i>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-header-placeholder" v-if="isLoading"></div>
    <div class="edit-offer-container" v-if="!isLoading">
      <div class="edit-offer-header">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-6">
              <div class="pt-3 pb-3">
                <h5 class="mb-0">
                  {{ offer.customer_name }}&nbsp;<span
                    class="mb-1 ms-4 small-text"
                    >#{{ id }}</span
                  >
                </h5>
              </div>
            </div>
            <div class="col-6 text-end">
              <!-- <a
                :href="
                  'https://rental-api.greenified.se/api/offers/pdf/' +
                  offer.offer_number
                "
                class="btn btn-outline-dark btn-sm me-3"
              >-->
              <button
                class="btn btn-outline-dark btn-sm me-3"
                @click="downloadPDF()"
              >
                {{ $t("downloadPdf") }}
              </button>
              <!-- </a>-->

              <button
                v-if="!offer.edit"
                class="btn btn-dark btn-sm"
                @click="offer.edit = true"
              >
                {{ $t("editParameter") }}
              </button>
              <button
                v-if="offer.edit"
                class="btn btn-dark btn-sm"
                @click="offer.edit = false"
              >
                {{ $t("closeParameters") }}
              </button>
            </div>
          </div>
        </div>
        <div
          style="
            background: #f7f5ef;
            margin-bottom: -7px;
            margin-top: 7px;
            height: calc(100vh - 150px);
          "
          v-if="offer.edit"
        >
          <div class="container">
            <div class="pb-4 pt-2">
              <div class="form-group mt-3">
                <label for="project"> {{ $t("projectName") }}*</label>
                <input
                  @blur="updateOffer"
                  type="text"
                  v-model="offer.project"
                  class="form-control"
                  id="project"
                />
              </div>

              <div class="row mt-3">
                <div class="col-6">
                  <div class="form-group">
                    <label for="comment">
                      {{ $t("bindingPeriodInMonths") }}*</label
                    >

                    <input
                      @blur="updateOffer"
                      type="number"
                      :min="offer.flex.low_value === '1' ? 3 : 13"
                      max="72"
                      v-model="offer.period"
                      class="form-control"
                      id="comment"
                      :placeholder="$t('bindingPeriodInMonths')"
                    />
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="comment"> {{ $t("flexParameter") }}*</label>
                    <v-select
                      @input="updateOffer"
                      v-model="offer.flex"
                      :options="flexParams"
                      label="meaning"
                      :clearable="false"
                    >
                      <template #open-indicator="{ attributes }">
                        <span v-bind="attributes"
                          ><span class="vue-select-chevron"></span
                        ></span>
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>
              <div class="form-group mt-3">
                <label for="external_text"> {{ $t("externalText") }}</label>
                <input
                  @blur="updateOffer"
                  type="text"
                  v-model="offer.order_text"
                  class="form-control"
                  id="external_text"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page-header-placeholder"></div>
      <div class="container">
        <div
          class="edit-offer-line-wrapper"
          v-for="(line, index) in offer.lines"
          :key="`line-${index}`"
        >
          <div class="is-loading" v-if="line.loading">
            <div class="align-items-center h-100">
              <i class="fas fa-circle-notch fa-spin"></i>
            </div>
          </div>
          <view-line
            :line="line"
            :currency="offer.currency"
            v-if="line.readonly"
            @toggle-display="toggleDisplay"
          ></view-line>
          <div style="background: #f7f5ef !important" v-if="!line.readonly">
            <div class="p-3">
              <div class="row">
                <div class="col-9">
                  <div class="row">
                    <div class="col-4">
                      <div class="form-group">
                        <label> {{ $t("articleNumber") }}</label>
                        <input
                          @change="updateOfferLine(line)"
                          type="text"
                          v-model="line.article"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-8">
                      <div class="form-group">
                        <label for="headline"> {{ $t("designation") }}</label>
                        <input
                          @change="updateOfferLine(line)"
                          type="text"
                          min="0"
                          max="100"
                          v-model="line.headline"
                          class="form-control"
                          id="headline"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-4">
                      <div class="form-group">
                        <label for="category"> {{ $t("category") }}</label>
                        <v-select
                          id="category"
                          :clearable="false"
                          v-model="line.category"
                          :options="parentCategories()"
                        >
                          <template #open-indicator="{ attributes }">
                            <span v-bind="attributes"
                              ><span class="vue-select-chevron"></span
                            ></span> </template
                        ></v-select>
                      </div>
                      <div class="form-group mt-3" :key="line.category.id">
                        <label for="subcategory">
                          {{ $t("subCategory") }}</label
                        >
                        <v-select
                          id="category"
                          :clearable="false"
                          @input="updateOfferLine(line)"
                          v-model="line.subcategory"
                          :options="childCategories(line.category.id)"
                        >
                          <template #open-indicator="{ attributes }">
                            <span v-bind="attributes"
                              ><span class="vue-select-chevron"></span
                            ></span> </template
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-8">
                      <div class="row">
                        <div class="form-group">
                          <label> {{ $t("supplier") }}</label>
                          <v-select
                            :clearable="false"
                            @input="updateOfferLine(line)"
                            v-model="line.levkod"
                            :options="suppliers"
                            label="leverantor"
                          >
                            <template #open-indicator="{ attributes }">
                              <span v-bind="attributes"
                                ><span class="vue-select-chevron"></span
                              ></span> </template
                          ></v-select>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-4">
                          <div class="form-group">
                            <label for="comment"> {{ $t("quantity") }}</label>
                            <input
                              @change="updateOfferLine(line)"
                              type="text"
                              v-model="line.quantity"
                              class="form-control"
                              id="comment"
                              :placeholder="$t('quantity')"
                            />
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="form-group">
                            <label> {{ $t("priceBeforeDiscount") }}</label>
                            <input
                              @change="updateOfferLine(line)"
                              type="text"
                              v-model="line.price"
                              class="form-control"
                              placeholder="0"
                            />
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="form-group">
                            <label> {{ $t("purchaseDiscount") }}</label>
                            <input
                              @change="updateOfferLine(line)"
                              type="text"
                              min="0"
                              max="100"
                              v-model="line.discount"
                              class="form-control"
                              :placeholder="$t('discount')"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-3">
                  <div class="form-group">
                    <label> {{ $t("text") }}</label>
                    <textarea
                      @change="updateOfferLine(line)"
                      style="height: 219px"
                      v-model="line.text"
                      class="form-control"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div class="p-3 pt-1">
                <div class="row">
                  <div
                    class="col"
                    v-for="question in questions"
                    :key="`line-${index}-${question.id}`"
                  >
                    <div class="form-group">
                      <label for="project">{{ question.title }}</label>

                      <div
                        class="form-check"
                        v-for="singleQuestion in question.lines"
                        :key="`line-${index}-${question.id}--${singleQuestion.id}`"
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          @change="updateOfferLine(line)"
                          :value="singleQuestion.id"
                          v-model="line.price_adjust[singleQuestion.main]"
                          :key="`line-${index}-${question.id}--${singleQuestion.id}`"
                          :id="`line-${index}-${question.id}--${singleQuestion.id}`"
                        />
                        <label
                          class="form-check-label"
                          :for="`line-${index}-${question.id}--${singleQuestion.id}`"
                        >
                          {{ singleQuestion.sub_t }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="offer-edit-line" v-if="!line.readonly">
              <div class="p-3">
                <div class="row">
                  <div class="col-3 offer-edit-line-total-price">
                    {{ $t("monthlyCost") }}:
                    <i
                      class="fas fa-circle-notch fa-spin"
                      v-if="line.loading"
                    ></i>
                    <span v-if="parseInt(line.final_price) && !line.loading">{{
                      formatPrice(line.quantity * line.final_price)
                    }}</span>
                  </div>
                  <div class="col-3 offer-edit-line-pce-price">
                    <span v-if="parseInt(line.final_price) && !line.loading"
                      >{{ formatPrice(line.final_price) }} / st</span
                    >
                  </div>
                  <div class="col-6 text-end">
                    <button
                      class="btn btn-danger btn-sm"
                      @click="deleteOfferLine(line)"
                    >
                      {{ $t("delete") }}
                    </button>
                    <button
                      class="btn btn-outline-dark btn-sm ms-3"
                      @click="
                        line.readonly = true;
                        global_index = index;
                      "
                    >
                      {{ $t("close") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center pt-5">
          <button class="btn btn-dark" @click="addLineToOffer">
            {{ $t("addLine") }}
          </button>
        </div>
      </div>
      <div class="edit-offer-footer" :class="{ 'in-view': !isLoading }">
        <div class="container">
          <div class="row">
            <div class="col-6">
              <div class="pt-4 pb-4">
                <h5>
                  {{ offer.lines.length }}
                  {{ offer.lines.length === 1 ? $t("row") : $t("rows") }}
                </h5>
              </div>
            </div>
            <div class="col-6 text-end">
              <div class="pt-4 pb-4">
                <h5>
                  {{ $t("monthlyCost") }}:
                  <span v-if="!isRefreshing">{{
                    formatPrice(offer.total_price)
                  }}</span>
                  <i
                    class="fas fa-circle-notch fa-spin ms-3 d-inline-block"
                    v-if="isRefreshing"
                  ></i>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import RentalAdministration from "@/services/api/rentalAdministration";
import MainLayout from "@/layouts/MainLayout";
import ViewLine from "@/components/offer/ViewLine";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "OfferEdit",

  components: {
    MainLayout,
    ViewLine,
    vSelect,
  },
  mounted() {
    this.getQuestions();
  },
  props: {
    id: String,
  },

  computed: {
    count() {
      return this.$store.state.language;
      // Or return basket.getters.fruitsCount
      // (depends on your design decisions).
    },
  },
  watch: {
    count() {
      this.refetchQuestions();
    },
  },

  data() {
    return {
      editOffer: false,
      isLoading: true,
      isRefreshing: false,
      questions: [],
      flexParams: [],
      suppliers: [],
      global_index: null,
      categories: [],
      offer: {
        lines: [],
        edit: false,
      },
    };
  },

  methods: {
    parentCategories() {
      let categories = new Set(
        this.categories.map((item) => item.huv_id + "|" + item.huvudkategori)
      );
      let formattedCategories = [];
      Array.from(categories).forEach((item) => {
        let value = item.split("|");
        formattedCategories.push({ id: value[0], label: value[1] });
      });
      return formattedCategories;
    },

    childCategories(parentCategory) {
      let localCategories = this.categories.filter(
        (item) => item.huv_id === parentCategory
      );
      let categories = new Set(
        localCategories.map((item) => item.under_id + "|" + item.underkategori)
      );
      let formattedCategories = [];
      Array.from(categories).forEach((item) => {
        let value = item.split("|");
        formattedCategories.push({ id: value[0], label: value[1] });
      });
      return formattedCategories;
    },

    formatPrice(price) {
      return parseFloat(price).toLocaleString("sv-SE", {
        style: "currency",
        currency: this.offer.currency,
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });
    },
    toggleDisplay(line_number, object) {
      this.offer.lines.forEach((element, index) => {
        if (element.line_number === line_number) {
          this.offer.lines[index] = object;
          this.global_index = line_number;
        } else {
          this.offer.lines[index].readonly = true;
        }
      });
    },
    updateOffer() {
      let minValue = this.offer.flex.low_value === "1" ? 3 : 13;
      if (this.offer.period >= minValue && this.offer.period <= 72) {
        RentalAdministration.updateOffer(
          this.offer.offer_number,
          this.offer
        ).then((response) => {
          this.offer = response.data.data;
        });
      } else {
        this.$toast.error(this.$t("bindingPeriodNotValid", [minValue]), {
          timeout: 3000,
        });
      }
    },

    deleteOfferLine(line) {
      if (confirm(this.$t("confirmDeleteRow"))) {
        line.loading = true;
        this.isRefreshing = true;
        RentalAdministration.deleteOfferLine(this.offer.offer_number, line)
          .then((response) => {
            this.isRefreshing = false;
            this.offer = response.data.offer;

            this.$toast.success(this.$t("rowIsDeleted"), {
              timeout: 3000,
            });
          })
          .catch(() => {
            this.$toast.error(this.$t("couldNotDeleteRow"), {
              timeout: 3000,
            });
            this.isRefreshing = false;
          });
      }
    },

    updateOfferLine(line) {
      line.loading = true;
      this.isRefreshing = true;

      RentalAdministration.updateOfferLine(this.offer.offer_number, line)
        .then((response) => {
          this.offer = response.data.offer;
          let currentLineNumber =
            line.line_number !== null
              ? line.line_number
              : response.data.line_number;
          this.isRefreshing = false;

          this.offer.lines.forEach((element, index) => {
            if (element.line_number === currentLineNumber) {
              this.offer.lines[index].readonly = false;
            } else {
              this.offer.lines[index].readonly = true;
            }
          });
        })
        .catch(() => {});
    },
    addLineToOffer() {
      let price_adjust = {};
      this.questions.forEach((question) => {
        price_adjust[question.id] = question.lines[0];
      });
      this.offer.lines.push({
        order_number: this.offer.offer_number,
        levkod: "",
        article: "",
        text: "",
        line_number: null,
        quantity: 1,
        price: "",
        discount: 0,
        category: "",
        final_price: 0,
        subcategory: "",
        readonly: false,
        loading: false,
        price_adjust: [null, "11", "21", "31", "41"],
      });
    },
    refetchQuestions: function () {
      this.isLoading = true;
      RentalAdministration.getOffer(this.id)
        .then((response) => {
          this.offer = response.data.data;
          RentalAdministration.getOfferQuestions()
            .then((response) => {
              this.questions = response.data;
              this.offer.lines.forEach((element, index) => {
                if (element.line_number === this.global_index) {
                  this.offer.lines[index].readonly = false;
                }
              });
              this.isLoading = false;
            })
            .catch(() => {});
        })
        .catch(() => {});
      RentalAdministration.getOfferCategories()
        .then((response) => {
          this.categories = response.data;
        })
        .catch(() => {});
    },
    getQuestions: function () {
      this.isLoading = true;
      RentalAdministration.getOffer(this.id)
        .then((response) => {
          this.offer = response.data.data;
          RentalAdministration.getOfferQuestions()
            .then((response) => {
              this.questions = response.data;
              this.isLoading = false;

              if (this.offer.lines.length === 0) {
                this.addLineToOffer();
              }
            })
            .catch(() => {});
        })
        .catch(() => {});

      RentalAdministration.getOfferFlexParameters()
        .then((response) => {
          this.flexParams = response.data;
        })
        .catch(() => {});

      RentalAdministration.getOfferSuppliers()
        .then((response) => {
          this.suppliers = response.data;
        })
        .catch(() => {});

      RentalAdministration.getOfferCategories()
        .then((response) => {
          this.categories = response.data;
        })
        .catch(() => {});
    },
    downloadPDF: function () {
      RentalAdministration.getOfferPdf(this.id)
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/pdf" }),
            url = window.URL.createObjectURL(blob);

          window.open(url);
        })
        .catch(() => {});
    },
  },
};
</script>
